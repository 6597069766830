import { SpinnerIcon } from '@lib/theme/components/SpinnerIcon';

import { ButtonContentProps } from '../../types/Button.type';

const iconClassName = 'w-5 h-5 flex';

export const ButtonContent = ({
  children,
  icon,
  leftIcon,
  rightIcon,
  loadingIndicatorTitle,
  isLoading,
}: ButtonContentProps) => (
  <div className="flex flex-1 items-center justify-center gap-1">
    {isLoading && (
      <SpinnerIcon
        className={iconClassName}
        title={loadingIndicatorTitle || 'Loading...'}
      />
    )}
    {leftIcon && !isLoading && <div className={iconClassName}>{leftIcon}</div>}
    {icon && !isLoading && <div className={iconClassName}>{icon}</div>}
    {!icon && !isLoading && children}
    {rightIcon && !isLoading && (
      <div className={iconClassName}>{rightIcon}</div>
    )}
  </div>
);
